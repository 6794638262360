import React from 'react';
import { motion } from 'framer-motion';
import Logo from '../Imagenes/logo-sherlock.webp'

const transicionBottomTop = (ComponenteOriginal) => {
    const TransicionComponent = () => {
        console.log(ComponenteOriginal)
        const listItems = [1, 2, 3, 4, 5]; 
    return (
        <>
            <ComponenteOriginal />
            <motion.div 
                className='slide-in'
                initial={{scaleY:0}}
                animate={{scaleY:0}}
                exit={{scaleY:1}}
                transition={{ duration:1.2, ease: [0.22, 1, 0.36, 1]}}
            >
                <img src={Logo} alt='logo' />
            </motion.div>
            <motion.div
                className='slide-out'
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: 1.2, ease: [0.22, 1, 0.36, 1] }}
            >
                <img src={Logo} alt='logo' />
            </motion.div>
        </>
    );
};

    return TransicionComponent;
};

export default transicionBottomTop;