import React from 'react'
import ImageGallery from "react-image-gallery";
import { useRef } from 'react';

const GaleriaIntro = ({introSecciones}) => {
    const isPlaying = useRef(true);
    const renderCustomControls = () => {
        if(isPlaying.current!=true){
            const currentIndex = isPlaying.current.getCurrentIndex();
            const seccion = introSecciones[currentIndex].seccion;
            if(seccion=="izquierda"){
                return (
                    <div className="custom-controls-container d-flex justify-content-center position-relative">
                        <div className='seccion izquierda position-absolute py-5 px-4 z-5 pe-none'>
                            <div className='d-flex justify-content-center flex-column h-100'>
                                <h2 className="poppins text-light titulo-mediano fw-bold">{introSecciones[currentIndex].subtitulo}</h2>
                                <h1 className="poppins text-light titulo-grande fw-bold">{introSecciones[currentIndex].titulo}</h1>
                                <p className='py-1 pe-sm-5 pe-0'>{introSecciones[currentIndex].parrafoUno}</p>
                                <p className='py-1 pe-sm-5 pe-0'>{introSecciones[currentIndex].parrafoDos}</p>
                                <button className='pe-auto'>
                                    <a href={introSecciones[currentIndex].path}>{introSecciones[currentIndex].textoBoton}</a>
                                </button>
                            </div>
                        </div>
                    </div>
                );
            } else if(seccion=="derecha"){
                return (
                    <div className="custom-controls-container d-flex justify-content-center position-relative">
                        <div className='seccion derecha position-absolute py-5 px-4 z-5 pe-none'>
                            <div className='d-flex justify-content-center flex-column h-100'>
                                <h2 className="poppins text-light titulo-mediano fw-bold">{introSecciones[currentIndex].subtitulo}</h2>
                                <h1 className="poppins text-light titulo-grande fw-bold">{introSecciones[currentIndex].titulo}</h1>
                                <p className='py-1 pe-sm-5 pe-0'>{introSecciones[currentIndex].parrafoUno}</p>
                                <p className='py-1 pe-sm-5 pe-0'>{introSecciones[currentIndex].parrafoDos}</p>
                                <button className='pe-auto'>
                                    <a href={introSecciones[currentIndex].path}>{introSecciones[currentIndex].textoBoton}</a>
                                </button>
                            </div>
                        </div>
                    </div>
                );
            } else{
                console.log("Prueba")
            }
        } 
    };

    return (
    <ImageGallery 
        ref={isPlaying}
        items={introSecciones}
        showPlayButton={false}
        autoPlay={true}
        showFullscreenButton={false}
        showNav={false}
        showThumbnails={false}
        showBullets={true}
        lazyLoad={true}
        slideInterval={3000} // Aquí defines el tiempo de transición en milisegundos (3000ms = 3 segundos)
        onMouseOver={() => {
            isPlaying.current.pause();
        }}
        onMouseLeave={() => {
            isPlaying.current.play();
        }}
        renderCustomControls={renderCustomControls}
    />
    )
}

export default GaleriaIntro