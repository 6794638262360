import React from 'react'
import { useRef,useEffect } from 'react';
import GaleriaProdutos from './GaleriaProdutos';
import GaleriaIntro from './GaleriaIntro';
import camion from '../Imagenes/sher44.jpg'
import cargo from '../Imagenes/SugarCane.webp'
import camion1 from '../Imagenes/camion1.jpg'
import transicionBottomTop from '../Transicion/transicionBottomTop';

const Home = () => {
    const isMountedRef = useRef(false);
    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);

    const produtos = [
        {
            original: "http://localhost:3000/static/media/sherlock-tr4.1ec4ecdd588fcb4d9d7b.webp",
            thumbnail: "https://unsplash.it/1000/600",
            titulo: "Sherlock TR4",
            path: "http://localhost:3000/Produtos/sherlock-tr4"
        },
        {
            original: "",
            thumbnail: "",
            titulo: "",
            path: ""
        },
        {
            original: "",
            thumbnail: "",
            titulo: "",
            path: ""
        },
        {
            original: "",
            thumbnail: "",
            titulo: "",
            path: ""
        },
        {
            original: "",
            thumbnail: "",
            titulo: "",
            path: ""
        }
    ]
    const introSecciones = [
{
            original: camion,
            titulo: "Sherlock 44",
            subtitulo: "",
            parrafoUno: "Lacre Sherlock 44, LÍDER DE MERCADO, ideal para BIG BAGS DE AÇÚCAR E FERTILIZANTES. Com 44 cm de comprimento total. Suporta mais de 25 KG de força; de rápida lacração.",
            parrafoDos: "",
            path: "/Contato",
            textoBoton: "CONTATO",
            seccion: "izquierda"
        },
        {
            original: camion,
            titulo: "Sherlock 44",
            subtitulo: "",
            parrafoUno: "Lacre Sherlock 44, LÍDER DE MERCADO, ideal para BIG BAGS DE AÇÚCAR E FERTILIZANTES. Com 44 cm de comprimento total. Suporta mais de 25 KG de força; de rápida lacração.",
            parrafoDos: "",
            path: "/Contato",
            textoBoton: "CONTATO",
            seccion: "izquierda"
        },{
            original: cargo,
            titulo: "Nossa história",
            subtitulo: "",
            parrafoUno: "Recolhendo 20 anos de experiência dos seus antecessores, atua no segmento de lacres e selos de segurança, tendo logrado um merecido prestígio a nível internacional pela qualidade e segurança os seus produtos.",
            parrafoDos: "",
            path: "/Empresa",
            textoBoton: "EMPRESA",
            seccion: "derecha"
        },{
            original: camion1,
            titulo: "TR4",
            subtitulo: "",
            parrafoUno: "Triplica a resistência ás temperaturas, não pode ser aberto na água quente, (a violação mais comum nos obsoletos lacres de âncora em polipropileno, ainda utilizados..).RESISTÊNCIA A TRAÇÃO: 50 Kgf no dinamômetro. (triplica também essa função).GRAVAÇÕES : com código de barras e QR (precisão absoluta na transmissão das informações)",
            parrafoDos: "",
            path: "/Contato",
            textoBoton: "CONTATO",
            seccion: "izquierda"
        }
    ]

    return (
        <main>
            <div className='imagen-intro'>
                <GaleriaIntro introSecciones={introSecciones} />
            </div>
            <div className="main-container">
                <div className="container-xxl seccion-grande contenido-intro">
                    <h1 className='poppins text-center mt-5 pt-4 fw-semibold px-md-3 px-0'>Somos líderes em lacres de segurança com a melhor relação qualidade-preço do mercado agrícola.</h1>
                    <div className="px-md-5">
                        <p className="px-sm-5 fs-5 my-5 text-center">Há mais de duas décadas, nossa empresa tem sido uma referência na indústria de lacres de segurança. Desde o nosso início, há mais de 20 anos, nos dedicamos a fornecer soluções confiáveis e eficazes para proteger seus ativos mais valiosos. </p>
                        <p className="px-sm-5 fs-5 mb-5 text-center">Nossa vasta experiência nos permitiu aperfeiçoar nossos produtos e serviços, adaptando-nos constantemente às necessidades em constante mudança do mercado e garantindo a satisfação dos nossos clientes.</p>
                    </div>
                </div>
                <div className="divider py-5 mb-5">
                    <h1 className='poppins fs-1 fw-semibold text-center d-flex gap-4 justify-content-center'>PRODUTOS EM DESTAQUE
                        <svg width="50" height="50" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                            <path d="M9 12l2 2l4 -4" />
                        </svg>
                    </h1>
                </div>
                <div className="mb-5 d-flex justify-content-center">
                    <div className="col-sm-11 col-md-9 col-lg-7 position-relative overflow-hidden">
                        <GaleriaProdutos produtos={produtos} />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default transicionBottomTop(Home)