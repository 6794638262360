import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Imagenes/logo-sherlock.webp'

const Navbar = () => {
    return (
        <nav className='navbar'>
            <div className='container-xxl h-100'>
                <ul className='d-flex align-items-center position-relative h-100 w-100 gap-5 px-0 mx-0 py-0 my-0'>
                    <div className="position-absolute logo-container d-flex align-items-center gap-5">
                        <Link className='logo' to="/">
                            <img src={Logo} alt="Logo Sherlock" />
                        </Link>
                        <div className="fone">
                            <a href="tel:+1191230-0361" className='poppins my-0'>Fone:
                                <span>
                                    <svg className='ms-3 me-2' width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                    </svg>
                                    11 91230-0361
                                </span>
                            </a>
                        </div>
                    </div>
                    
                    <input type="checkbox" id="hamburger_checkbox" />
                    <label htmlFor="hamburger_checkbox" className="hamburger_button">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                    
                    <div className='d-flex items'>
                        <li className='list-unstyled poppins fw-medium'><Link to="/">Inicio</Link></li>
                        <li className='list-unstyled poppins fw-medium'><Link to="/Empresa">Empresa</Link></li>
                        <li className='list-unstyled poppins fw-medium'>
                            <span className="dropdown-wrapper">
                                <Link to="/Produtos">Produtos</Link>
                                <ul className="dropdown-content px-0">
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/medida">POR MEDIDA</Link></li>
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/utilidade">POR UTILIDADE</Link></li>
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/sherlock-44">SHERLOCK 44CM</Link></li>
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/sherlock-45">SHERLOCK 45CM</Link></li>
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/lacres-triple-trava">LACRE TRIPLA TRAVA 16/23/28/35CM</Link></li>
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/sherlock-tr4">SHERLOCK TR4</Link></li>
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/lacre-breakaway">LACRE BREAKAWAY</Link></li>
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/dupla-anchora">DUPLA ANCORA SHERLOCK</Link></li>
                                    <li className='list-unstyled px-3 text-center'><Link to="/Produtos/cadeado-blindado">SHERLOCK CADEADO BLINDADO</Link></li>
                                </ul>
                            </span>
                        </li>
                        <li className='list-unstyled poppins fw-medium'><Link to="/Dicas">DICAS DE SEGURANÇA</Link></li>
                        <li className='list-unstyled poppins fw-medium'><Link to="/Contato">Contato</Link></li>
                    </div>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar