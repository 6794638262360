import React, { useEffect, useRef } from 'react'
import transicionBottomTop from '../Transicion/transicionBottomTop';
import Logo from '../Imagenes/logo-sherlock.webp'

const Dicas = () => {
    const isMountedRef = useRef(false);
    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);
    return (
        <main className='dicas-container'>
            <div className="container-xxl pb-5 mb-5">
                <h1 className='big-title poppins fw-bold py-5 text-center'><span className='position-relative'>DICAS DE SEGURANÇA</span></h1>
                <div className="d-flex justify-content-center flex-wrap gap-5">
                    <div className="col-3 rounded-5">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className='col-8'>
                        <div className='d-flex gap-5 position-relative'>
                            <div className="py-5 d-flex align-items-center justify-content-center icon position-absolute">
                                <svg width="50" height="50" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 11l3 3l8 -8" />
                                    <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
                                </svg>
                            </div>
                            <div className="col-12 py-5 dica d-flex justify-content-center align-items-center">
                                <p className='fw-bold'>A escolha correta de um sistema de selagem se baseia na regra de que o valor das perdas irá ditar o nível de segurança pretendido e seu custo.</p>
                            </div>
                        </div>
                        <div className='d-flex reverse gap-5 position-relative'>
                            <div className="py-5 d-flex align-items-center justify-content-center icon position-absolute">
                                <svg width="50" height="50" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M4.028 13.252c-.657 -.972 -1.028 -2.078 -1.028 -3.252c0 -1.804 .878 -3.449 2.319 -4.69m2.49 -1.506a11.066 11.066 0 0 1 4.191 -.804c4.97 0 9 3.134 9 7c0 1.799 -.873 3.44 -2.307 4.68m-2.503 1.517a11.066 11.066 0 0 1 -4.19 .803c-1.913 0 -3.686 -.464 -5.144 -1.255" />
                                    <path d="M5 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                    <path d="M5 17c0 1.42 .316 2.805 1 4" />
                                    <path d="M3 3l18 18" />
                                </svg>
                            </div>
                            <div className="col-12 py-5 dica d-flex justify-content-center align-items-center">
                                <p className='fw-bold'>Nos últimos anos vem aparecendo no mercado, os erroneamente chamados lacres de segurança; com preços e qualidade que fazem duvidar da segurança oferecida, confeccionados com matérias primas e processos sem qualquer controle ou normas produtivas.</p>
                            </div>
                        </div>
                        <div className='d-flex gap-5 position-relative'>
                            <div className="py-5 d-flex align-items-center justify-content-center icon position-absolute">
                                <svg width="50" height="50" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
                                    <path d="M12 11m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                    <path d="M12 12l0 2.5" />
                                </svg>
                            </div>  
                            <div className="col-12 py-5 dica d-flex justify-content-center align-items-center">
                                <p className='fw-bold'>Desde o começo das atividades, a SHERLOCK visa produzir exclusivamente produtos de alta segurança, garantindo para os nossos clientes o melhor custo- beneficio do mercado.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default transicionBottomTop(Dicas)